import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslation, Trans } from 'react-i18next'

import { TEXT_STYLES } from 'TEXT_STYLES'

import { loginWithAmazon, loadAmazonLoginSdk } from 'helpers/ppc'
import { PPC_SYNC_STATUS } from 'constants/account'
import { MWS_SYNC_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/account/mwsSync'

import { Button, ButtonType } from '@junglescout/edna'
import { AccountSyncError } from 'src/js/components/account/ConnectToMwsPpcSteps/AccountSyncError/AccountSyncError'

const PpcSyncComponent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${TEXT_STYLES.BodyBlack}
`
const PpcIcon = styled.img`
  width: 230px;
  height: 115px;
`

const Message = styled.div`
  margin: 15px 0 15px;
  font-size: 16px !important;
  color: ${props => props.theme.colors.grey700};
`

const Note = styled.div`
  margin-top: 30px;
  a {
    font-size: 12px !important;
  }
`

const Link = styled.a`
  & {
    font-weight: normal !important;
  }
  ${TEXT_STYLES.H4Orange}
`

const getHowToArticleLink = fromSetupPage => {
  const dataId = fromSetupPage
    ? MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.SetupWizardPpcHowToArticle
    : MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.PpcHowToArticle
  return (
    <Trans i18nKey="mwsSync:PpcSyncStep.note">
      Follow this{' '}
      <Link
        href="https://support.junglescout.com/hc/en-us/articles/360036000974"
        target="_blank"
        data-id={dataId}>
        how-to article
      </Link>{' '}
      if you have multiple accounts or have issues with syncing.
    </Trans>
  )
}

function renderInitial(props) {
  const { btnType, handleAmazonLogin, t, fromSetupPage } = props

  const message = t(
    'mwsSync:PpcSyncStep.message',
    'Log in to Amazon to sync your PPC account.'
  )
  const buttonLabel = t('mwsSync:PpcSyncStep.buttonLabel', 'Log in with Amazon')

  const dataId = fromSetupPage
    ? MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.SetupWizardPpcLogIn
    : MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.PpcLogIn
  return (
    <PpcSyncComponent>
      <PpcIcon src="//djga94vwgu7gt.cloudfront.net/assets/junglescout/ppc-lwa-login@3x.png" />
      <Message>{message}</Message>
      <Button btnType={btnType} onClick={handleAmazonLogin} dataId={dataId}>
        {buttonLabel}
      </Button>
      <Note>*{getHowToArticleLink(fromSetupPage)}</Note>
    </PpcSyncComponent>
  )
}

function renderFailure(props) {
  const { btnType, handleAmazonLogin, fromSetupPage } = props

  const dataId = fromSetupPage
    ? MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.SetupWizardPpcTryAgain
    : MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.PpcTryAgain

  return (
    <AccountSyncError
      btnType={btnType}
      type="ppc"
      btnDataId={dataId}
      handleButtonClick={handleAmazonLogin}
    />
  )
}

class PpcSyncLwa extends Component {
  componentDidMount() {
    loadAmazonLoginSdk()
  }

  handleAmazonLogin = () => {
    const { sellerAccountId, withMwsSync } = this.props
    loginWithAmazon(sellerAccountId, withMwsSync)
  }

  render() {
    const {
      btnType,
      ppcSyncStatus,
      t,
      fromSetupPage,
      region,
      flagData
    } = this.props
    const renderProps = {
      btnType,
      handleAmazonLogin: this.handleAmazonLogin,
      t,
      fromSetupPage,
      region,
      flagData
    }

    switch (ppcSyncStatus) {
      case PPC_SYNC_STATUS.initial:
        return renderInitial(renderProps)
      case PPC_SYNC_STATUS.failure:
        return renderFailure(renderProps)
      default:
        return null
    }
  }
}

PpcSyncLwa.defaultProps = {
  btnType: ButtonType.PRIMARY,
  withMwsSync: false,
  fromSetupPage: false,
  handleAmazonLogin: () => {},
  region: null,
  flagData: null
}

PpcSyncLwa.propTypes = {
  btnType: PropTypes.string,
  ppcSyncStatus: PropTypes.string.isRequired,
  sellerAccountId: PropTypes.number,
  withMwsSync: PropTypes.bool,
  fromSetupPage: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  handleAmazonLogin: PropTypes.func,
  region: PropTypes.string,
  flagData: PropTypes.object
}

const mapStateToProps = ({ globalData: { flagData } }) => {
  return { flagData }
}

export default withTranslation(['mwsSync'])(
  connect(mapStateToProps)(PpcSyncLwa)
)
